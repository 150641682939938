<template>
  <v-app>
    <v-banner class="lavBanner" single-line height="400">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/ARjiameng-banner.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color:#FFF">
              <div>
                <v-card-title class="white--text font-size-48">
                  全国诚招合作伙伴，诚邀您的加入
                </v-card-title>
                <v-card-title class="white--text font-size-14 mt-8 justify-center">
                  5G+AR/VR互动游戏 助力营销业绩倍增
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <div class="app-InvestmentPromotion">
      <v-container class="max-width-1200">
        <div class="public-title" style="margin:62px 0 46px 0">
          <p>ADVANTAGE</p>
          <div>
            <span>我们的优势</span>
            <span>5G时代+政策扶持时代机遇，顺势而为</span>
          </div>
        </div>
        <div class="d-flex justify-center">
          <v-card elevation="0">
            <v-tabs v-model="advantageTab" hide-slider vertical>
                <v-tab
                  v-for="(item,index) in data.advantage"
                  :key="index"
                  :class="['mr-3 justify-start',(data.advantage.length-1) != index?'mb-2':'']"
                  :style="{'background':advantageTab == index?'linear-gradient(90deg, #F5F8FD, #FFFFFF)':'#F5F8FD','border':advantageTab == index?'2px solid #BED3FF':'','box-sizing':'border-box','width':'260px','height':'120px'}"
                >
                  <img :src="item.iconImg" width="50" height="50" />
                  <v-card-title class="font-size-18 px-2" @mouseover="mouseover" @mouseleave="mouseleave">{{ item.tab }}</v-card-title>
                </v-tab>
                <v-tab-item continuous='true'
                  v-for="item in data.advantage"
                  :key="item.tab"
                  transition="fade-transition"
                >
                  <v-card style="box-shadow:none">
                    <v-img
                      :src="item.src"
                      class="white--text align-left flex-grow-0"
                      width="790"
                      height="380"
                    >
                    <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular
                                indeterminate
                                size="80"
                                color="red lighten-5"
                              ></v-progress-circular>
                            </v-row>
                    </template>
                      <div class="d-flex flex-column  lavbg">
                        <div
                          v-for="(childrenItem, index) in item.titleList"
                          :key="index"
                          :class="[index == 0? 'mt-6':'','ml-6']"
                        >
                          <v-card-title
                            class="white--text font-size-16 font-weight-bold"
                            v-text="childrenItem.title"
                          ></v-card-title>
                          <v-card-text
                            class="white--text font-size-14"
                            v-text="childrenItem.content"
                          ></v-card-text>
                        </div>
                      </div>
                    </v-img>
                  </v-card>
                </v-tab-item>
            </v-tabs>
          </v-card>
        </div>
      </v-container>
    </div>
    <v-container class="max-width-1200">
      <div class="public-title" style="margin-top:66px">
          <p>BRAND HONOR</p>
          <div>
            <span>品牌荣誉</span>
            <span>5G时代+政策扶持时代机遇，顺势而为</span>
          </div>
      </div>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-img src="https://h5.ophyer.cn/official_website/other/InvestmentPromotion-bg-new.jpg" height="420" contain>
          <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template></v-img>
        </v-col>
      </v-row>
    </v-container>
    <div class="app-InvestmentPromotion lavStyle">
      <v-container class="max-width-1200">
        <div class="public-title" style="margin-top:10px;margin-bottom:-20px">
          <p>PLATFORM RIGHTS</p>
          <div>
            <span>合作伙伴计划</span>
          </div>
        </div>
        <v-row class="justify-center">
          <v-col cols="4" v-for="(item,index) in data.items" :key="index" class="d-flex child-flex">
                <v-card elevation="0">
                    <div>
                      <div class="d-flex justify-center">
                        <v-img :src="item.src" width="120" class="flex-grow-0" height="120">
                          <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      size="80"
                                      color="red lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                          </template>
                        </v-img>
                      </div>
                      <v-card-title class="font-size-24 justify-center py-3" v-text="item.tab"></v-card-title>
                      <div class="d-flex justify-center">
                        <v-card-text class="font-size-14 text-align-center justify-center py-0" style="color:#666;width:266px" v-text="item.content"></v-card-text>
                      </div>
                    </div>
                </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
let _this;
import lavData from '@/static/lavData.js'
export default {
  name: "InvestmentPromotion",
  data: () => ({
    imgLoad: false,
    windowHight: window.innerHeight / 2,
    data:lavData.InvestmentPromotion,
    // tab: null,
    advantageTab: null,
    timer:null
  }),
  mounted() {
    _this = this;
    this.bannerLoad = true;
    clearInterval(_this.timer)
    _this.setTimer()
  },
  methods: {
    handleLoad: () => {
      _this.imgLoad = true;
    },
    setTimer:() =>{
      _this.timer = setInterval(() => {
        _this.advantageTab ++
      },2000)
    },
    mouseover:()=>{
      clearInterval(_this.timer)
    },
    mouseleave:() => {
      _this.setTimer()
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-tabs--vertical > .v-window{
  flex: 0 1 auto !important;
  -webkit-flex:	0 1 auto !important;
  -ms-flex:	0 1 auto !important;
  -moz-flex: 	0 1 auto !important;
}
.lavBanner {
  border: none !important;
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.lavStyle{
  padding-bottom: 60px;
}
.app-InvestmentPromotion {
  ::v-deep .v-tabs-bar__content {
    display: flex;
    justify-content: center;
  }
  ::v-deep .v-divider--vertical{
    height: 100px;
  }
}
.lavbg{
  width:50%;
  height: 422px;
  background-size: 100% 100%;
  background-image: url('../../assets/images/InvestmentPromotion/bg.png');
}
::v-deep .v-card__text{
  line-height: 20px!important;
}
</style>